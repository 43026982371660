<script>
import {
  BBadge,
  BIconArrowLeftRight,
  BIconPlus,
  BButtonGroup,
} from 'bootstrap-vue'

import { global } from './utils'
export default {
  name: 'Admin',
  components: {
    BBadge,
    BButtonGroup,
    BIconArrowLeftRight,
    BIconPlus,
  },
  props: {
    user: [Object, String],
    categoriesProp: Array,
  },
  data() {
    return {
      global,
      errors: {},
      loadings: {},
      categories: [],
      categoriesPreview: [],
      newCategoryLabel: '',
    }
  },
  methods: {
    setError(name) {
      this.$set(this.errors, name, true)
    },
    clearError(name) {
      this.$set(this.errors, name, false)
    },
    setLoading(name) {
      this.$set(this.loadings, name, true)
    },
    clearLoading(name) {
      this.$set(this.loadings, name, false)
    },
    getCategoriesEndpoint() {
      this.setLoading('getCategoriesEndpoint')
      this.clearError('getCategoriesEndpoint')
      this.axios
        .get(this.$root.apiUrl + '/agitator/campaigns/categories/', {
          headers: {
            Authorization: this.authHeader,
          },
        })
        .then((response) => {
          this.global.debug(response.data)
          this.categories = JSON.parse(JSON.stringify(response.data.categories))
          this.categoriesPreview = JSON.parse(
            JSON.stringify(response.data.categories)
          )
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('getCategoriesEndpoint')
          this.clearLoading('getCategoriesEndpoint')
        })
        .finally(() => this.clearLoading('getCategoriesEndpoint'))
    },
    addCategoryEndpoint() {
      this.setLoading('addCategoryEndpoint')
      this.clearError('addCategoryEndpoint')
      let categories = JSON.parse(JSON.stringify(this.categories))
      categories.push({ label: this.newCategoryLabel })
      const body = { categories }
      this.axios
        .post(this.$root.apiUrl + '/agitator/campaigns/categories/', body, {
          headers: {
            Authorization: this.authHeader,
          },
        })
        .then((response) => {
          this.global.debug(response.data)
          this.newCategoryLabel = ''
          this.getCategoriesEndpoint()
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('addCategoryEndpoint')
          this.clearLoading('addCategoryEndpoint')
        })
        .finally(() => this.clearLoading('addCategoryEndpoint'))
    },
    addCategory() {
      this.newCategoryLabel && this.addCategoryEndpoint()
    },
    saveCategories() {
      this.isCategoriesModified && this.saveCategoriesEndpoint()
    },
    saveCategoriesEndpoint() {
      this.setLoading('saveCategoriesEndpoint')
      this.clearError('saveCategoriesEndpoint')
      const body = {
        categories: this.categoriesPreview,
      }
      this.axios
        .patch(this.$root.apiUrl + '/agitator/campaigns/categories/', body, {
          headers: {
            Authorization: this.authHeader,
          },
        })
        .then((response) => {
          this.global.debug(response.data)
          this.categories = JSON.parse(JSON.stringify(this.categoriesPreview))
        })
        .catch((error) => {
          this.global.debug(error)
          this.setError('saveCategoriesEndpoint')
          this.clearLoading('saveCategoriesEndpoint')
        })
        .finally(() => this.clearLoading('saveCategoriesEndpoint'))
    },
    activateCategory(id) {
      this.categoriesPreview.forEach((category) => {
        if (category.id === id) {
          category.active = 1
        }
      })
    },
    deactivateCategory(id) {
      this.categoriesPreview.forEach((category) => {
        if (category.id === id) {
          category.active = 0
        }
      })
    },
    resetCategories() {
      this.categoriesPreview = JSON.parse(JSON.stringify(this.categories))
    },
  },
  computed: {
    authHeader() {
      return this.user && this.user.token && 'Bearer ' + this.user.token
    },
    isAddCategoryEndpointLoading() {
      return (
        this.loadings['addCategoryEndpoint'] &&
        this.loadings['addCategoryEndpoint'] === true
      )
    },
    isSaveCategoriesEndpointLoading() {
      return (
        this.loadings['saveCategoriesEndpoint'] &&
        this.loadings['saveCategoriesEndpoint'] === true
      )
    },
    activeCategories() {
      return this.categoriesPreview.filter((category) => {
        return category.active !== 0
      })
    },
    inactiveCategories() {
      return this.categoriesPreview.filter((category) => {
        return category.active === 0
      })
    },
    isCategoriesModified() {
      return (
        JSON.stringify(this.categories) !==
        JSON.stringify(this.categoriesPreview)
      )
    },
  },
  mounted() {
    this.categories = JSON.parse(JSON.stringify(this.categoriesProp))
    this.categoriesPreview = JSON.parse(JSON.stringify(this.categories))
  },
}
</script>

<style scoped lang="scss"></style>
